<template>
  <r-e-dialog :title="title" :visible.sync="dialogVisible" show-footer top="5vh"
              width="600px"
              @click-submit="handleSubmitPublish" @click-cancel="handleCancel" @close="handleCancel">
    <el-form ref="form" :model="sizeForm" label-width="80px" size="mini">

      <el-form-item label="合同结束时间" label-width="70">
          <el-date-picker format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" v-model="sizeForm.endDate" style="width: 70%;"></el-date-picker>
      </el-form-item>
      <el-form-item label-width="70">
        注：电子合同无法修复<br/>
        删除的账单需要手动添加
      </el-form-item>
    </el-form>
  </r-e-dialog>
</template>

<script>
//添加房租账单
import {recoverapi} from "@/api/house-resource";
import {getContractByUuidOrLeasorUuid} from "@/api/contract"
import {MessageSuccess, MessageError, MessageWarning} from "@custom/message";

export default {
  name: "dialog-add-bill",
  data() {
    return {
      title: '合同恢复',
      row:null,
      dialogVisible: false,
      sizeForm:{
        endDate:null
      }
    }
  },
  props: {
    currentHouse: {
      type: Object,
      default: () => ({})
    },
    zUuid: {
      type: String,
      default: null,
    }
  },
  async created() {

  },
  methods: {
    recoverapiclick(row){
    this.row = row
    },
    // 提交
    handleSubmitPublish(){
      recoverapi({uuid:this.row.uuid,endDate:this.sizeForm.endDate}).then(res => {
        console.log(res)
        this.handleCancel()
      })
    },
    // 取消
    handleCancel(){
      this.dialogVisible = false;
      console.log(this.sizeForm.endDate)

    }
    },


  watch: {

  }
}
</script>
<style lang="scss" scoped>
.el-select {
  width: 100%;
}

.flex {
  display: flex;

  /deep/ .el-form-item {
    flex: 1;
    white-space: nowrap;
  }

  /deep/ .amount {
    .el-input {
      width: 42%;
    }
  }

  /deep/ .tag-group {
    white-space: normal;

    .el-tag {
      margin: 0 VW(5px) VH(5px) 0;
      cursor: pointer;
    }
  }

  /deep/ .samplePhotos {
    white-space: normal;
  }
}
</style>
<style>
.v-modal {
  z-index: 2000 !important;
}
</style>
